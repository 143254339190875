<template>
  <div>
    <p>
      用户在使用技术开发方（即，以下统称“技术开发方”）提供的各项服务之前，应仔细阅读本《用户协议》（以下简称“本协议”）。
      用户一旦登录或使用技术开发方的服务，即视为用户已了解并明示同意本协议各项内容，本协议立即在用户与本技术开发方之间产生法律效力。
      用户登录、使用本产品服务的全部活动将受到本协议的约束并承担相应的责任和义务。如用户不同意本协议任何内容的，请用户立即停止使用技术开发方所提供的全部服务。
      根据《中华人民共和国网络安全法》、《电信和互联网用户个人信息保护规定》及相关法律法规的规定，同时依据技术开发方与其合作伙伴之间的相关协议，
      用户必须已明示授权技术开发方合作伙伴（以下简称“合作伙伴”）、在此明示授权并委托技术开发方及其关联公司通过官方或相关实名认证平台、
      信用信息平台（包括但不限于：征信机构、银行信用信息平台、网络借贷平台、消费金融平台、第三方支付平台、公积金平台、投资理财平台等）
      及其它相关平台查询、验证、存储用户的个人信用信息，并输出给合作伙伴对用户的个人信用进行评估与参考使用。用户理解并同意，具体的授权查询、
      验证、存储及输出的内容以合作伙伴要求查询、验证、存储、输出以及技术开发方及其关联公司实际查询、验证、存储、输出的信息为准。
      技术开发方及其关联公司会在授权范围内对相关个人信息予以处理（包括但不限于为保护用户个人信息而加密处理、掩码处理等一切为实现相关协议目的而进行的所有必要处理）
      并仅提供给合作伙伴使用，但用户与合作伙伴之间因授权的有效性、授权内容、授权范围、授权期限等发生的争议纠纷与技术开发方无关。
      如用户对合作伙伴的上述授权事项有任何异议或争议的，应立即停止使用技术开发方所提供的全部服务。用户使用本服务的，即表明用户已明示对合作伙伴、
      技术开发方及其关联公司授权查询、验证、处理、存储、在约定范围内使用其个人信息，并对授权的效力、查询验证的内容、查询验证平台、处理方式、
      使用范围等相关事项无任何事实或法律上的异议或争议。
      鉴于用户须授权合作伙伴并由该合作伙伴告知本服务后方能进入、登录并使用本服务，用户登录
      或使用本服务时起即视为用户与技术开发方的合作伙伴之间已存在合法的、充分的、必要的、不可撤销的授权，且用户已清楚知晓其授权提供相关信息可
      能对其造成的相关不利影响，如负面的信用评价等。为保护用户个人信息，技术开发方会采取合理措施对用户信息进行严格保密，同时督促并要求该特定合作伙
      伴进行严格保密。未经用户授权，技术开发方及其关联公司不会将用户信息提供给任何其他方。
    </p>

    <p>一、协议主体</p>
    <p>本协议是用户与技术开发方关于用户使用本服务所订立的协议。</p>

    <p>二、关于本服务</p>
    <p>
      本服务内容是指技术开发方通过本应用程序向其合作伙伴及用户提供的相关服务（简称“本服务”）。
    </p>
    <p>
      对用户使用的本服务，技术开发方会不断丰富用户使用本服务的终端、形式等。
    </p>
    <p>许可的范围：</p>
    <p>
      -（1）技术开发方授予用户一项个人的、不可转让及非排他性的许可，以使用本应用程序。
    </p>
    <p>
      -（2）本条及本协议其他条款未明示授权的其他一切知识产权权利仍由技术开发方保留。技术开发方如果未行使前述任何权利，并不构成对该权利的放弃。
    </p>
    <p>三、账号登录</p>
    <p>
      为使用本应用程序用户可能需要输入个人信息进行登录与使用。
      用户清楚知晓，其向技术开发方提供的账户仅限本人使用，否则，用户可能会对用户或他人造成侵权。用户承诺并同意，使用同一设备、同一身份证号或账号使用本协议项下服务的，均视为用户本人的行为。用户应妥善保管自身设备、身份证件及账号密码信息，审慎交由他人使用或使用他人设备、身份证件或账户信息，如用户违反本约定给用户或他人造成损失的，技术开发方不应也不会承担任何法律责任。用户若因此给技术开发方造成损失的，应承担技术开发方所遭受的全部损失。
    </p>

    <p>四、应用程序的使用</p>
    <p>
      如果用户从非技术开发方合作伙伴的应用程序或非技术开发方合作伙伴处获取本应用程序或与本应用程序名称相同的安装程序，技术开发方无法保证该应用程序能够正常使用，并对因此给用户造成的损失不予负责。
    </p>

    <p>五、应用程序的更新</p>
    <p>
      为了增进用户体验、完善服务内容，技术开发方将不断努力为用户时时提供应用程序更新（这些更新可能会采取应用程序替换、修改、功能强化、版本升级等形式）。
    </p>
    <p>
      为了改善用户体验，并保证服务的安全性和功能的一致性，技术开发方有权不经向用户特别通知而对应用程序进行更新，或者对应用程序的部分功能效果进行改变或限制。
    </p>
    <p>六、用户个人信息保护</p>
    <p>
      保护用户个人信息是技术开发方的一项基本原则，技术开发方将会采取合理的措施保护用户的个人信息。除法律法规规定及用户授权的情形外，未经用户许可技术开发方不会向任何第三方公开、透露用户个人信息。
    </p>
    <p>
      用户在登录账号或使用本服务的过程中，可能需要填写一些必要的信息。若用户填写的信息不真实或不完整，则可能无法正常使用本服务。
    </p>
    <p>
      一般情况下，用户可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，用户可能无法修改注册时提供的初始注册信息及其他验证信息。
    </p>
    <p>
      技术开发方将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。
    </p>
    <p>七、授权事项及行为规范</p>
    <p>授权事项</p>
    <p>
      -（1）用户充分理解并同意：用户在使用本服务时，可能需要使用用户终端设备的相关权限、接口及相关设备信息等才能实现相应的功能。
    </p>
    <p>
      -（2）用户可以选择不向技术开发方提供用户的某些信息，但因此可能会导致相关服务功能无法实现。
    </p>
    <p>
      -（3）为实现本协议目的为合作伙伴及用户提供更加优质、安全的服务，用户同意并明示授权技术开发方及其关联公司对用户的相关个人信息进行查询、验证、存储、处理并在约定范围内使用（提供给用户已授权的合作伙伴对用户进行信用评估及参考使用）。技术开发方及其关联公司对用户的个人信息进行严格保密。本协议项下的授权为不可撤销的授权。
    </p>

    <p>
      -（4）用户知晓并明示授权同意技术开发方及其关联公司依据相关法律法规的规定，受合作伙伴委托向第三方征信机构或数据机构等合法查询、验证、审核用户信息，上述信息包括但不限于个人基本信息、特征信息（包括但不限于用户的法院失信信息、网络失信信息、是否为羊毛党信息、是否曾使用通讯小号及可疑设备信息等）、关联信息（即用户的身份证信息、手机号、手机设备及银行卡之间的关联关系，以判断用户信息是否有异常，该关联关系不涉及具体的个人敏感信息）、借贷交易信息、网络投资理财信息、公积金信息、公用事业信息、央行征信报告、个人网络数据信息等合作伙伴需要验证或参考使用的相关用户信息。技术开发方具体查询、验证及审核的信息最终以合作伙伴需要验证、需要参考使用及实际验证与使用的信息为准。技术开发方对所获取的信息，仅在用户与合作伙伴之间有关个人信用信息评估等合作伙伴业务相关工作中使用。技术开发方及其关联公司将对所获取的信息向该合作伙伴进行提供，除此之外，未经用户授权，技术开发方及其关联公司不得也不会向其他机构或个人泄露、披露或提供用户的信息
    </p>

    <p>用户禁止行为除非法律允许或技术开发方书面许可，用户不得从事下列行为：</p>
    <p>-（1）删除本应用程序及其副本上关于著作权的信息。</p>
    <p>
      -（2）对本应用程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本应用程序的源代码。
    </p>
    <p>
      -（3）对技术开发方拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版等。
    </p>
    <p>
      -（4）通过修改或伪造应用程序运行中的指令、数据，增加、删减、变动应用程序的功能或运行效果，或者将用于上述用途的应用程序、方法进行运营或向公众传播，无论这些行为是否为商业目的。
    </p>
    <p>
      -（5）自行、授权他人或利用第三方应用程序对本应用程序及其组件、模块、数据等进行干扰。
    </p>
    <p>-（6）其他未经技术开发方明示授权的行为。</p>
    <p>
      对自己行为负责用户充分了解并同意，用户必须为自己对合作伙伴的授权（包括但不限于授权方式、授权内容及授权期限等）以及其账户下的相关行为负责。技术开发方会督促合作伙伴获取用户的授权后方能对相关信息进行查询、验证或使用并要求合作伙伴对用户的信息进行严格保密，但用户应对使用本服务时接触到的内容自行加以判断，如对授权相关事项及信息安全有任何异议或争议的，应立即停止使用本服务。技术开发方无法且不会对用户与合作伙伴之间的任何争议或纠纷而承担责任，用户未按约定要求立即停止使用本服务的，技术开发方对非因技术开发方的原因而产生的任何风险或损失将不承担任何责任。
    </p>

    <p>八、知识产权声明</p>
    <p>
      技术开发方是本应用程序的知识产权权利人。本应用程序的著作权、商标权、专利权、商业秘密等知识产权，以及与本应用程序相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，技术开发方依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
    </p>

    <p>
      未经技术开发方或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
    </p>
    <p>九、终端安全责任</p>
    <p>
      用户理解并同意，本应用程序或本服务同大多数互联网应用程序、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他应用程序或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁用户终端的信息和数据的安全，继而影响本应用程序、本服务的正常使用等）。因此，用户应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。出现上述情况时技术开发方将努力在第一时间与相关方配合，及时进行修复，但是由此给用户造成的损失技术开发方在法律允许的范围内免责。
    </p>

    <p>
      用户不得制作、发布、使用、传播用于窃取技术开发方账号及他人个人信息、财产的恶意程序。
    </p>
    <p>
      维护应用程序安全与正常使用是技术开发方和用户的共同责任，技术开发方将按照行业标准合理审慎地采取必要技术措施保护用户的终端信息和数据安全。
    </p>
    <p>
      在法律允许的范围内，技术开发方对以下情形导致的服务中断或受阻不承担责任：
    </p>
    <p>-（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。</p>
    <p>-（2）用户或技术开发方的电脑软件、系统、硬件和通信线路出现故障。</p>
    <p>-（3）用户操作不当。</p>
    <p>-（4）用户通过非技术开发方授权的方式使用本服务。</p>
    <p>-（5）其他技术开发方无法控制或合理预见的情形。</p>
    <p>十、不可抗力及合理免责</p>
    <p>
      “不可抗力”是指在本协议签订后发生的、受影响一方无法预见、无法避免并无法克服的客观情况。此等事件包括但不限于水灾、火灾、旱灾、台风、地震及其它自然灾害、罢工、骚动、暴乱及战争以及政府部门的作为或不作为、法律法规或政策调整、数据来源变更(包括但不限于其服务内容及形式的变更)、国内数据渠道瘫痪、黑客攻击、计算机病毒侵入、新型病毒爆发、因电信运营商问题导致网络中断服务器不可访问、停电、系统故障、传输线路、通信故障等技术开发方无法控制的因素。因受不可抗力影响而不能履行或不能完全履行本协议的不视为违约，不应承担相应违约责任
      。
    </p>

    <p>十一、其他</p>
    <p>
      用户使用本应用程序或本服务即视为用户已阅读并同意受本协议的约束。技术开发方有权在必要时修改本协议条款。用户可以在本应用程序、本服务的最新版本中查阅相关协议条款。本协议条款变更后，如果用户继续登录、使用本应用程序、本服务，即视为用户已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用本应用程序。
    </p>

    <p>
      本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
    </p>
    <p>
      若用户和技术开发方之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交被告方所在地人民法院管辖。
    </p>
    <p>
      本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。5.
      本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
